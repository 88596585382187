import React from 'react';
import { graphql } from 'gatsby';
import { generateCategoryPagePath } from '../utils';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageContentWrapper, GroupPageLink } from './templates.style';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { PostTitle } from '../components/post-details/post-details.style';

export const query = graphql`
  query CategoryListQuery($group: String!) {
    allMdx(filter: { frontmatter: { group: { eq: $group } } }) {
      categories: distinct(field: frontmatter___category)
    }
  }
`;

const GroupTemplate = ({ pageContext, data }) => {
  const categories = data.allMdx.categories;
  return (
    <Layout>
      <SEO
        title={`${pageContext.group} - Wishes and E-Cards`}
        description={`Wishes and E-Cards on various ${pageContext.group} topics.`}
      />
      <PageContentWrapper>
        <PostTitle>{pageContext.title}</PostTitle>
        <MDXRenderer>{pageContext.body}</MDXRenderer>
        {categories.map((category, index) => (
          <div>
            <GroupPageLink
              key={index}
              to={`/${generateCategoryPagePath(pageContext.group, category)}`}
            >
              {category}
            </GroupPageLink>
          </div>
        ))}
      </PageContentWrapper>
    </Layout>
  );
};

export default GroupTemplate;
